import { connect } from 'react-redux'
import TwoFactor from '../components/Spider/Instagram/Challenge/TwoFactor'
import {sendtwofactorcode, clear_cache} from '../actions/instagram/instaAPI'

const mapStateToProps = (state, ownProps) => ({
    instaAPI: state.instaAPI,
    location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
    sendtwofactorcode: (username, code, type, crds) => dispatch(sendtwofactorcode(username, code, type, crds)),
    clearCache: () => dispatch(clear_cache())
})

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactor)