import * as types from './types'
import axios from 'axios'
import {
    loginInstaEndpoint,
    challengeInstaEndpoint,
    challengeCodeInstaEndpoint,
    twoFactorInstaEndpoint,
    isAuthInstaEndpoint,
    currentUserInstaEndpoint,
    currentUserCredentialInstaEndpoint,
    updateMediaEndpoint,
    updatesInstaEndpoint,
    startWSGInstaEndpoint,
    statusFinishedEndpoint,
    startSendMessagesEndpoint,
    lastPackageEndpoint,
    statusMessagesEndpoint
} from '../base'

export const is_auth = () => dispatch => {
    dispatch(loader(true))
    
    axios.get(isAuthInstaEndpoint)
    .then(
        response => {
            dispatch(loader(false))
            if (response.data.status === "success") {
                console.log("Response Login Success: ", response);                
                dispatch(loginSuccess(response.data.status, response.data.data));                
            }
            else {
                console.warn("Error Login: ", response);
                dispatch(error(response.data.status, response.data.message));
            }            
        }
    )
    .catch(err => {
        dispatch(loader(false))
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const login = (username, password, hash) => dispatch => {
    dispatch(loader(true))
    let data = {
        username,
        password,
        hash
    }    
    axios.post(loginInstaEndpoint, data)
    .then(
        response => {
            dispatch(loader(false))
            if (response.data.status === "success") {
                console.log("Response Login Success: ", response);
                console.log("Header spider-in-auth: ", response.headers["spider-in-auth"]);
                dispatch(loginSuccess(response.data.status, response.data.data));                
            }
            else if (response.data.status === "challenge") {
                console.log("Response Login: ", response);                
                if (response.data.data.type === "StepData") {
                    dispatch(challengeRequired(response.data.status, response.data.data))
                } else if (response.data.data.type === "SubmitPhoneRequired") {

                } else if (response.data.data.type === "TwoFactorRequired") {
                    dispatch(challengeTwoFactor(response.data.status, response.data.data))                    
                }
            }
            else {
                console.warn("Error Login: ", response);
                dispatch(error(response.data.status, response.data.message));
            }            
        }
    )
    .catch(err => {
        dispatch(loader(false))
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const challenge = (username, code, type, crds) => dispatch => {
    dispatch(loader(true))
    let data = {
        username,
        code,
        type,
        crds
    }
    axios.post(challengeInstaEndpoint, data)
    .then(
        response => {
            dispatch(loader(false))
            if (response.data.status === "success") {
                dispatch(challengeSuccess(response.data.status, response.data.data))         
            }            
            else {
                console.warn("Error Challenge: ", response);
                dispatch(error(response.data.status, response.data.message));
            }            
        }
    )
    .catch(err => {
        dispatch(loader(false))
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const sendcode = (username, code, type, crds) => dispatch => {
    dispatch(loader(true))
    let data = {
        username,
        code,
        type,
        crds
    }
    axios.post(challengeCodeInstaEndpoint, data)
    .then(
        response => {
            dispatch(loader(false))
            console.log("Response Verif Code:", response.data)
            if (response.data.status === "success") {
                dispatch(loginSuccess(response.data.status, response.data.data))         
            } 
            else if(response.data.status === "challenge") {
                if (response.data.data.type === "TwoFactorRequired") {
                    dispatch(challengeTwoFactor(response.data.status, response.data.data))                    
                }
            }          
            else {
                console.warn("Error Challenge: ", response);
                dispatch(error(response.data.status, response.data.message));
            }            
        }
    )
    .catch(err => {
        dispatch(loader(false))
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const sendtwofactorcode = (username, code, type, crds) => dispatch => {
    dispatch(loader(true))
    let data = {
        username,
        code,
        type,
        crds
    }
    axios.post(twoFactorInstaEndpoint, data)
    .then(
        response => {
            dispatch(loader(false))
            console.log("Response Verif Code:", response.data)
            if (response.data.status === "success") {
                dispatch(loginSuccess(response.data.status, response.data.data))         
            } 
            else {
                console.warn("Error Challenge Two Factor: ", response);
                dispatch(error(response.data.status, response.data.message));
            }            
        }
    )
    .catch(err => {
        dispatch(loader(false))
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const current_user = () => dispatch => {
    dispatch(loader(true))    
    axios.get(currentUserInstaEndpoint)
    .then(
        response => {                 
            if (response.data.status === "success") {
                dispatch(currentUserSuccess(response.data.status, response.data.data));
                dispatch(current_user_credentials());

            }else if(response.data.status === "login"){
                dispatch(loginSuccess(response.data.status, response.data.data));  
            }
            else {
                console.warn("Error Login: ", response);
                dispatch(error(response.data.status, response.data.message));
            }
            dispatch(loader(false))
        }
    ).catch(err => {
        dispatch(loader(false))
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

const current_user_credentials = () => dispatch => {
    dispatch(loader(true))    
    axios.get(currentUserCredentialInstaEndpoint)
    .then(
        response => {                 
            if (response.data.status === "success") {
                dispatch(currentCredentialSuccess(response.data.status, response.data.data));
                if(response.data.data.numMediaCollected === 0){
                    dispatch(update_media(response.data.data.userName))
                }
                dispatch(check_status_wsg(response.data.data.userName, response.data.data.campaign, "instagram")) 
                dispatch(set_btn_msg(false))                               
            }else if(response.data.status === "login"){
                dispatch(loginSuccess(response.data.status, response.data.data));  
            }
            else {
                console.warn("Error Login: ", response);
                dispatch(error(response.data.status, response.data.message));
            }
            dispatch(loader(false))
        }
    ).catch(err => {
        dispatch(loader(false))
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const update_media = (influencer) => dispatch => {
    
    let url = `${updateMediaEndpoint}?influencer=${influencer}`  
    axios.get(url)
    .then(
        response => {                 
            //TODO: Start checker data
            
        }
    ).catch(err => {
        
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const check_updates = () => dispatch => {    
    axios.get(updatesInstaEndpoint)
    .then(
        response => {                 
            //TODO: Start checker data
            dispatch(checkUpdatesSuccess(response.data.status, response.data.data))
        }
    ).catch(err => {
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const start_wsg = () => dispatch => {
    dispatch(updateWSGStatus("starting"))
    let url = `${startWSGInstaEndpoint}`    
    axios.get(url)
    .then(
        response => {                 
            //TODO: Start checker data
            if(response.data.status === "success")
                dispatch(updateWSGStatus("started"))
            else if(response.data.status === "process"){
                dispatch(updateWSGStatus("process"))                
            }
            else if(response.data.status === "login"){
                dispatch(updateWSGStatus("finished"))
                dispatch(loginSuccess(response.data.status, response.data.data));                
            }
            else{
                dispatch(updateWSGStatus("finished"))
                dispatch(error("error", response.data.message))
            }                
        }
    ).catch(err => {
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const check_status_wsg = (influencer, campaign, channel) => dispatch => {
    
    let url = `${statusFinishedEndpoint}?influencer=${influencer}&campaign=${encodeURI(campaign)}&channel=${channel}`    
    axios.get(url)
    .then(
        response => {                 
            if(response.data.status === "success"){
                dispatch(updateWSGStatusFinished(response.data))
                if(response.data.is_ready){
                    setTimeout(() => {
                        dispatch(updateWSGStatus("finished"))
                    }, 2000)
                }else{
                    dispatch(updateWSGStatus("process"))
                }
            }
            else{
                console.error("Error:", response.data)
                dispatch(error("error", response.data.message))
            }           
        }
    ).catch(err => {
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const start_send_messages = (text) => dispatch => {
    
    let url = `${startSendMessagesEndpoint}/${encodeURI(text)}`    
    axios.get(url)
    .then(
        response => {                 
            if(response.data.status === "success"){
                dispatch(set_btn_msg(false))
            }
            else{
                console.error("Error:", response.data)
                dispatch(error("error", response.data.message))
            }           
        }
    ).catch(err => {
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const get_last_package = (influencer, campaign) => dispatch => {    
    let url = `${lastPackageEndpoint}/${influencer}/${encodeURI(campaign)}`    
    axios.get(url)
    .then(
        response => {                 
            if(response.data.status === "success"){
                let packageId = (response.data.package?.PackageID || response.data.package?.packageID || 0)                
                if(response.data.package == null){
                    dispatch(set_btn_msg(true))
                }else{
                    dispatch(set_package_id(packageId))
                }
            }
            else{
                console.error("Error:", response.data)
                dispatch(error("error", response.data.message))
            }           
        }
    ).catch(err => {
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}

export const get_msg_status = (packageID) => dispatch => {
    
    let url = `${statusMessagesEndpoint}/${packageID}`    
    axios.get(url)
    .then(
        response => {                 
            if(response.data.status === "success"){
                dispatch(status_msg_success(
                    response.data.all, 
                    response.data.sent, 
                    response.data.errors, 
                    response.data.finished))                
            }
            else{
                console.error("Error:", response.data)
                dispatch(error("error", response.data.message))
            }           
        }
    ).catch(err => {
        console.error("Server Error:", err)
        dispatch(error("error", err.message))
    })
}


export const loader = flag => ({
    type: types.LOADER,
    flag
})

export const clear_cache = () => ({
    type: types.CLEAR_CACHE,
    status: null,
    data: null
})

const loginSuccess = (status, data) => ({
    type: types.LOGIN_SUCCESS,
    status,
    data
})

const error = (status, message) => ({
    type: types.LOGIN_ERROR,
    status,
    message
})

const challengeRequired = (status, data) => ({
    type: types.CHALLENGE_REQUIRED,
    status,
    data
})

const challengeSuccess = (status, data) => ({
    type: types.CHALLENGE_SUCCESS,
    status,
    data
})

const challengeTwoFactor = (status, data) => ({
    type: types.CHALLENGE_TWOFACTOR,
    status,
    data
})

const currentUserSuccess = (status, user) => ({
    type: types.CURRENT_USER_SUCCESS,
    status,
    user
})

const currentCredentialSuccess = (status, credential) => ({
    type: types.CURRENT_CREDENTIAL_SUCCESS,
    status,
    credential
})

const checkUpdatesSuccess = (status, updates) => ({
    type: types.CHECK_UPDATES_SUCCESS,
    status,
    updates
})

const updateWSGStatus = (status) => ({
    type: types.UPDATE_WSG_STATUS,
    status
})

const updateWSGStatusFinished = (data) => ({
    type: types.WSG_STATUS_FINISHED_SUCCESS,
    all: data.all,
    finished: data.finished,
    errors: data.errors
})

const set_package_id = (id) => ({
    type: types.SET_PACKAGE_ID,
    id
})

export const change_event = (val) => ({
    type: types.CHANGE_EVENT,
    value: val
})

export const set_btn_msg = (flag) => ({
    type: types.SET_VISIBILITY_BTN_MSG,
    flag
})

const status_msg_success = (requsted, sent, errors, finished) => ({
    type: types.STATUS_MSG_SUCCESS,
    requsted,
    sent,
    errors,
    finished
})