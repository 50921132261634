import { connect } from 'react-redux'
import Main from '../components/Spider/Instagram/Main/Main'
import {
    is_auth, 
    current_user, 
    clear_cache,
    update_media,
    check_updates,
    start_wsg,
    check_status_wsg,
    start_send_messages,
    change_event,
    get_last_package,
    get_msg_status,
    set_btn_msg
} from '../actions/instagram/instaAPI'

const mapStateToProps = (state, ownProps) => ({
    instaAPI: state.instaAPI,
    location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
    isAuth: () => dispatch(is_auth()),
    getCurrentUser: () => dispatch(current_user()),
    clearCache: () => dispatch(clear_cache()),
    updateMedia: user => dispatch(update_media(user)),
    checkUpdates: () => dispatch(check_updates()),
    startWSG: () => dispatch(start_wsg()),
    checkStatusWSG: (influencer, campaign, channel) => dispatch(check_status_wsg(influencer, campaign, channel)),
    startSendMessages: (text) => dispatch(start_send_messages(text)),
    onChangeMessage: text => dispatch(change_event(text)),
    getLastPackage: (influencer, campaign) => dispatch(get_last_package(influencer, campaign)),
    getMsgStatus: id => dispatch(get_msg_status(id)),
    setBtnMsg: flag => dispatch(set_btn_msg(flag))
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)