import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import dashboard from './dashboard'
import spider from './spider'
import instaAPI from './instaAPI'

export default combineReducers({
    dashboard,
    spider,
    instaAPI,
    routing: routerReducer
});