export const host = "";
//export const host = "https://insta.emaze.com";
//export const host = "https://localhost:5001";

export const webservice3_host = "https://webservice3.emaze.com";


//** Dashboard ENDPOINTS */
export const baseEndpoint = "/api"
export const loginInstaEndpoint = `${host}${baseEndpoint}/in/login`;
export const challengeInstaEndpoint = `${host}${baseEndpoint}/in/challenge`;
export const challengeCodeInstaEndpoint = `${host}${baseEndpoint}/in/code`;
export const twoFactorInstaEndpoint = `${host}${baseEndpoint}/in/twofactor`;
export const isAuthInstaEndpoint = `${host}${baseEndpoint}/in/auth`;
export const currentUserInstaEndpoint = `${host}${baseEndpoint}/in/currentuser`;
export const currentUserCredentialInstaEndpoint = `${host}${baseEndpoint}/in/credential`;
export const updatesInstaEndpoint = `${host}${baseEndpoint}/in/updates`;
export const startWSGInstaEndpoint = `${host}${baseEndpoint}/in/start_generate`;
export const startSendMessagesEndpoint = `${host}${baseEndpoint}/in/start_message_process`;
export const updateMediaEndpoint = `${host}${baseEndpoint}/in/update_media`;


//** Webservice3 ENDPOINTS: */
//export const updateMediaEndpoint = `${webservice3_host}${baseEndpoint}/sitegen/update_media`;
export const statusFinishedEndpoint = `${webservice3_host}${baseEndpoint}/sitegen/finished`;
export const lastPackageEndpoint = `${webservice3_host}${baseEndpoint}/sitegen/last_package`;
export const statusMessagesEndpoint = `${webservice3_host}${baseEndpoint}/sitegen/message_status`;
