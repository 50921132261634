import * as types from '../actions/instagram/types'

const InitialState = {
    username: "",
    isAuthenticated: false,
    loader: false,
    influencer: null,
    credential: null,
    wsg:{
        status:"finished",
        sent: 0,
        errors: 0,
        finished: 0
    },
    messages:{
        text: "Thank you for being a loyal fan, we are sending you a free [WEBSITE] so you really feel part of the team.",
        requsted: 0,
        sent: 0,
        errors: 0,
        finished: false,
        packageId: 0,
        showBtn: true  
    }    
}

const instaAPI = (state=InitialState, action) => {
    switch(action.type){
        case types.LOADER:
            return {
                ...state,
                loader: action.flag
            }
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                username: action.data.username,
                isAuthenticated: action.data.isAuth,
                status: action.status,
                data: action.data
            }
        case types.LOGIN_ERROR:
            return {
                ...state,
                status: action.status,
                message: action.message,
                error: true
            }
        case types.CHALLENGE_REQUIRED:
            return{
                ...state,
                username: action.data.username,
                status: action.status,
                data: action.data
            }
        case types.CHALLENGE_SUCCESS:
            return{
                ...state,
                username: action.data.username,
                status: action.status,
                data: action.data
            }
        case types.CURRENT_USER_SUCCESS:
            return{
                ...state,
                status: action.status,
                influencer: action.user
            }
        case types.CURRENT_CREDENTIAL_SUCCESS:
            return{
                ...state,
                status: action.status,
                credential: action.credential
            }
        case types.CHECK_UPDATES_SUCCESS:
            return{
                ...state,
                status: action.status,
                credential: {
                    ...state.credential,
                    numFollowers: action.updates.numFollowers,
                    numMediaCollected: action.updates.numMediaCollected,
                    numWebsitesGenerated: action.updates.numWebsitesGenerated
                }
            }
        case types.CLEAR_CACHE:
            return{
                ...state,
                status: action.status,
                data: action.data
            }
        case types.UPDATE_WSG_STATUS:
            return{
                ...state,
                wsg: {
                    ...state.wsg,
                    status: action.status
                }
            }
        case types.WSG_STATUS_FINISHED_SUCCESS:
            return{
                ...state,
                wsg: {
                    ...state.wsg,
                    sent: action.all,
                    finished: action.finished,
                    errors: action.errors
                }
            }
        case types.SET_PACKAGE_ID:
            return{
                ...state,
                messages: {
                    ...state.messages,
                    packageId: action.id
                }
            }
        case types.CHANGE_EVENT:
            return{
                ...state,
                messages: {
                    ...state.messages,
                    text: action.value
                }
            }
        case types.SET_VISIBILITY_BTN_MSG:
            return{
                ...state,
                messages: {
                    ...state.messages,
                    showBtn: action.flag,
                    finished: false
                }
            }
        case types.STATUS_MSG_SUCCESS:
            return{
                ...state,
                messages: {
                    ...state.messages,
                    requsted: action.requsted,
                    sent: action.sent,
                    errors: action.errors,
                    finished: action.finished
                }
            }
        default:
            return {...state};
    }
}

export default instaAPI;