import React, {Component} from 'react'
import _ from 'lodash'
import Loader from '../../../Loader'
import {browserHistory} from 'react-router'


import {
    Box, Grid, Card, CardContent, CardActions, CardHeader,
    Button, Icon, Avatar, Divider,
    FormControl, FormLabel, RadioGroup, 
    FormControlLabel, Radio
} from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab';

import './Challenge.scss'

export default class Challenge extends Component{
    constructor(props){
        super(props)

        this.state = {
            selectedOption: ""
        }
    }

    componentDidUpdate(){
        const {instaAPI, location} = this.props
        if(!_.isNil(instaAPI.status) && !_.isNil(instaAPI.data) && !instaAPI.loader){
            if(instaAPI.status === "success"){
                let path = `${location.pathname}/${instaAPI.data.type}`
                browserHistory.push(path)
            }
        }
    }

    handleChange = (event) => {
        this.setState({selectedOption: event.target.value})
    }

    handleSendButton = event => {
        const {challenge, instaAPI} = this.props
        const {selectedOption} =this.state
        if(_.isString(selectedOption)){
            challenge(instaAPI.username, null, selectedOption, instaAPI.data?.crds)
        }        
    }

    render(){
        const {instaAPI} = this.props
        return(
            <Box m={"20px auto"}>
                <Grid container spacing={1} justify="center">
                    <Grid item md={4}>
                        <Card>
                            <CardHeader
                                className="Challenge-header"
                                avatar={
                                    <Avatar aria-label="user info">
                                        <Icon className="fab fa-instagram" fontSize="large" />
                                    </Avatar>
                                }
                                title="You have a challenge verification:"
                            />
                            <Divider />
                            <CardContent>
                                <Box p={1} className="Challenge-centerContent">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">You can choose a option of verification:</FormLabel>
                                        <RadioGroup aria-label="verification option" 
                                        name="challenge-options" 
                                            value={this.state.selectedOption} 
                                            onChange={this.handleChange}>
                                            <FormControlLabel value="phone" control={<Radio color="primary" />} label={instaAPI.data?.phone} />
                                            <FormControlLabel value="email" control={<Radio color="primary" />} label={instaAPI.data?.email} />                                            
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {
                                    _.isBoolean(instaAPI.error) && instaAPI.error ? 
                                    (
                                        <Box m={1}>
                                            <Alert severity="error">
                                                <AlertTitle><strong>Error</strong></AlertTitle>
                                                {instaAPI.message}
                                            </Alert>
                                        </Box>
                                    ) : (<></>)
                                } 
                            </CardContent>
                            <CardActions className="Challenge-just-center">
                                <Box p={2}>
                                    <Button variant="contained" 
                                        color="primary"
                                        onClick={this.handleSendButton}>Next</Button>
                                </Box>                                
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Loader visible={instaAPI.loader}></Loader>
            </Box>
        )
    }
}