import * as types from '../actions/dashboard/types'

const InitialState = {
    selectedCategory: null    
}

const dasboard = (state=InitialState, action) => {
    switch(action.type){
        case types.SELECT_CATEGORY:
            return {
                ...state,
                selectedCategory: action.item
            }
        default:
            return state;
    }
}

export default dasboard;