import { connect } from 'react-redux'
import Dashboard from '../components/Dashboard'

import {selectCategory} from '../actions/dashboard/dashboard'

const mapStateToProps = (state, ownProps) => ({
    ...state,
    location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
    selectCategory: (item) => dispatch(selectCategory(item))    
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)