import { connect } from 'react-redux'
import Challenge from '../components/Spider/Instagram/Challenge/Challenge'

//*** Actions: ***/
import {challenge, clear_cache} from '../actions/instagram/instaAPI'

const mapStateToProps = (state, ownProps) => ({
    instaAPI: state.instaAPI,
    location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
    challenge: (username, code, type, crds) => dispatch(challenge(username, code, type, crds)),
    clearCache: () => dispatch(clear_cache())
})

export default connect(mapStateToProps, mapDispatchToProps)(Challenge)