import React, {Component} from 'react'
import _ from 'lodash'
import Loader from '../../../Loader'
import {browserHistory} from 'react-router'


import {
    Box, Grid, Card, CardContent, CardActions, CardHeader,
    Button, Icon, Avatar, Divider,
    TextField, InputAdornment
} from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab';

import  '../Login/Login.scss'
import './ChallengeCode.scss'

export default class ChallengeCode extends Component{
    constructor(props){
        super(props)

        this.state = {
            code: ""
        }
    }

    componentDidUpdate(){
        const {instaAPI, location} = this.props
        if(!_.isNil(instaAPI.status) && !_.isNil(instaAPI.data) && !instaAPI.loader){
            let routes = location.pathname.split('/')
            let path = ""
            switch(instaAPI.status){
                case "success":
                    if(instaAPI.isAuthenticated){            
                        for (let i = 1; i < Math.min(3, routes.length); i++) {
                            path += `/${routes[i]}`     
                        }
                        browserHistory.push(path)
                    }
                    break;
                case "challenge":
                    if(instaAPI.data?.type === "TwoFactorRequired"){
                        path = location.pathname.replace("/code", "/twofactor")
                        browserHistory.push(path)
                    }
                    break;
                default:
                    break;
            }
        }
    }

    codeProps = {
        startAdornment: (
            <InputAdornment position="start" className="dft-color">
              <Icon className="fas fa-key" fontSize="small" />
            </InputAdornment>
          ),
    }

    handleCode = (event) => {
        this.setState({code: event.target.value})
    }

    handleButton = event => {
        const {sendcode, instaAPI} = this.props
        const {code} =this.state
        if(code?.length > 0){
            sendcode(instaAPI.username, code, "code", instaAPI.data?.crds)
        }
    }

    render(){
        const {instaAPI} = this.props
        return(
            <Box m={"20px auto"}>
                <Grid container spacing={1} justify="center">
                    <Grid item md={4}>
                        <Card>
                            <CardHeader
                                className="header"
                                avatar={
                                    <Avatar aria-label="user info">
                                        <Icon className="fab fa-instagram" fontSize="large" />
                                    </Avatar>
                                }
                                title="Enter the code."
                                subheader={instaAPI.data?.message}
                            />
                            <Divider />
                            <CardContent>
                                <Box p={1} className="centerContent">
                                    <TextField id="code" 
                                        label="Verification Code:" 
                                        InputProps={this.codeProps}
                                        onChange={this.handleCode}
                                        error={_.isBoolean(instaAPI.error) && instaAPI.error}
                                    ></TextField>
                                </Box>
                                {
                                    _.isBoolean(instaAPI.error) && instaAPI.error ? 
                                    (
                                        <Box m={1}>
                                            <Alert severity="error">
                                                <AlertTitle><strong>Error</strong></AlertTitle>
                                                {instaAPI.message}
                                            </Alert>
                                        </Box>
                                    ) : (<></>)
                                }
                            </CardContent>
                            <CardActions className="just-center">
                                <Box p={2}>
                                    <Button variant="contained" 
                                        color="primary"
                                        onClick={this.handleButton}>Send</Button>
                                </Box>                                
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Loader visible={instaAPI.loader}></Loader>
            </Box>
        )
    }
}