import { connect } from 'react-redux'
import Main from '../components/Spider/Facebook/Main/Main'


const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)