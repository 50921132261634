import React from 'react'
import PropTypes from 'prop-types'

import {Box, Icon} from '@material-ui/core'

import './Loader.scss'

const Loader = ({visible}) => {
    return visible ? 
    (
        <Box className="loader-container">
            <Icon className="fas fa-compact-disc fa-spin loader"></Icon>
        </Box>
    ) : (<></>)
}

Loader.propTypes = {
    visible: PropTypes.bool.isRequired
}

export default Loader;