export const findElementByPropName = (arr, propName, value) => {
    if(arr && arr.length > 0){
        return arr.find((elm) => {
            if(elm.hasOwnProperty(propName)){
                return elm[propName] === value
            }else{
                return false
            }
        })
    }else{
        return undefined
    }
}