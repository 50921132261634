import React, {Component} from 'react'
import _ from 'lodash'
import LZString from 'lz-string'
import  sha256  from 'sha256';
import Loader from '../../../Loader'
import {browserHistory} from 'react-router'


import {
    Box, Grid, Card, CardContent, CardActions, CardHeader,
    Button, Icon, Avatar, Divider,
    TextField, InputAdornment
} from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab';

import  './Login.scss'

export default class Login extends Component{
    constructor(props){
        super(props)

        this.state = {
            username: "",
            password: "",
            hash: ""            
        }
    }

    componentDidMount(){
        const {instaAPI, location} = this.props
        if(instaAPI.isAuthenticated){
            let routes = location.pathname.split('/')
            let path = ""
            for (let i = 1; i < routes.length - 1; i++) {
                path += `/${routes[i]}`     
            }
            browserHistory.push(path)
        }
    }

    componentDidUpdate(){
        const {instaAPI, location} = this.props
        let routes = location.pathname.split('/')
        if(instaAPI.isAuthenticated){            
            let path = ""
            for (let i = 1; i < routes.length - 1; i++) {
                path += `/${routes[i]}`     
            }
            browserHistory.push(path)
        }
        else if(!_.isNil(instaAPI.status))
        {
            switch(instaAPI.status){
                case "error":
                    console.error("Login Error: ", instaAPI)
                    break;
                case "challenge":
                    if(instaAPI.data?.type === "StepData"){
                        routes[routes.length - 1] = "challenge"
                        let path = `${routes.join('/')}` 
                        browserHistory.push(path)
                    }
                    else if(instaAPI.data?.type === "SubmitPhoneRequired"){
                        console.log("SubmitPhoneRequired", instaAPI)
                    }
                    else if(instaAPI.data?.type === "TwoFactorRequired"){
                        routes[routes.length - 1] = "challenge"
                        let path = `/${routes.join('/')}/twofactor` 
                        browserHistory.push(path)
                    }
                    break;
                default:
                    break;
            }                
        }
    }

    usernameProps = {
        startAdornment: (
            <InputAdornment position="start" className="dft-color">
              <Icon className="fas fa-user" fontSize="small" />
            </InputAdornment>
          ),
    }

    passProps = {
        startAdornment: (
            <InputAdornment position="start" className="dft-color">
              <Icon className="fas fa-lock" fontSize="small" />
            </InputAdornment>
          ),
    }

    handleUsername = (event) => {
        this.setState({username: event.target.value})
    }

    handlePassword = (event) => {
        this.setState({
            password: LZString.compressToBase64(event.target.value),
            hash: sha256(event.target.value)   
        })
    }

    handleLogin = (event) => {
        const { username, password, hash } = this.state
        const {login} = this.props
        login(username, password, hash)
    }

    render(){
        const {instaAPI} = this.props
        return(
            <Box m={"20px auto"}>
                <Grid container spacing={1} justify="center">
                    <Grid item md={4}>
                        <Card>
                            <CardHeader
                                className="header"
                                avatar={
                                    <Avatar aria-label="user info">
                                        <Icon className="fab fa-instagram" fontSize="large" />
                                    </Avatar>
                                }
                                title="Login to Instagram"
                            />
                            <Divider />
                            <CardContent>
                                <Box p={1} className="centerContent">
                                    <TextField id="username" 
                                        label="Username" 
                                        InputProps={this.usernameProps}
                                        onChange={this.handleUsername}
                                        error={_.isBoolean(instaAPI.error) && instaAPI.error}
                                    ></TextField>
                                </Box>
                                <Box p={1} className="centerContent">
                                    <TextField id="password" 
                                        type="password" 
                                        label="Password" 
                                        InputProps={this.passProps}
                                        onChange={this.handlePassword}
                                        error={_.isBoolean(instaAPI.error) && instaAPI.error}
                                    ></TextField>
                                </Box>
                                {
                                    _.isBoolean(instaAPI.error) && instaAPI.error ? 
                                    (
                                        <Box m={1}>
                                            <Alert severity="error">
                                                <AlertTitle><strong>Error</strong></AlertTitle>
                                                {instaAPI.message}
                                            </Alert>
                                        </Box>
                                    ) : (<></>)
                                }
                                
                                
                            </CardContent>
                            <CardActions className="just-center">
                                <Box p={2}>
                                    <Button variant="contained" color="primary" onClick={this.handleLogin}>Login</Button>
                                </Box>                                
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Loader visible={instaAPI.loader}></Loader>
            </Box>
        )
    }
}