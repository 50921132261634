import React, {Component} from 'react'
import _ from 'lodash'


import {Box, Grid, 
    Card, CardContent, CardActions,
    Button
} from '@material-ui/core'

import '../../Instagram/Login/Login.scss'
import './Main.scss'

export default class Main extends Component{
    constructor(props){
        super(props)
    }

    componentDidMount(){
        console.log("[Main]-[componentDidMount] - Props:", this.props)
    }    

    render(){
        
         
        return (
            <Box m={"20px auto"}>
                <Grid container spacing={1} justify="center">
                    <Grid item md={6}>
                        <Card>
                            <CardContent>
                                Facebook is coming soon...
                            </CardContent>
                            <CardActions>
                                <Button variant="contained" color="primary">Send</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}