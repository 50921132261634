import React, { Component } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';
import Thunk from 'redux-thunk';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';



import Dashboard from './containers/DashboardContainer'

import Spider from './containers/SpiderContainer'

//***  Instagram  ***/
import InstaMain from './containers/InstaMainContainer'
import Login from './containers/InstaLoginContainer'
import Challenge from './containers/InstaChallengeContainer'
import ChallengeCode from './containers/InstaChallengeCodeContainer'
import TwoFactor from './containers/InstaTwoFactorContainer'

//***  Facebook  ***/
import FbMain from './containers/FbMainContainer'


const middleware = [Thunk, routerMiddleware(browserHistory)]

//*** Developer mode
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

//*** Production mode
//const store = createStore(rootReducer, applyMiddleware(...middleware));

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store)

class App extends Component{
  render(){
    return(
      <Provider store={store}>
        <Router history={history}>
          <Route path="/" component={Dashboard}>
            <Route path="spider" component={Spider}>
              <Route exact path="in" component={InstaMain} />
              <Route path="in">
                <Route path="login" component={Login} />
                <Route path="challenge" component={Challenge} />
                <Route path="challenge/code" component={ChallengeCode} />
                <Route path="challenge/twofactor" component={TwoFactor} />
              </Route>
              <Route exact path="fb" component={FbMain} />
              
            </Route>
          </Route>
        </Router>
      </Provider>
    )
  }
}

export default App;
