import React, { Component } from 'react'
import _ from 'lodash'
import Loader from '../Loader'

import {browserHistory} from 'react-router'

import {Box, Tabs, Tab, AppBar} from '@material-ui/core'

import styles from './Spider.module.scss'

class Spider extends Component{
    constructor(props){
        super(props)                
    }

    componentDidMount(){
        console.log("[Spider]-[componentDidMount] - Props:", this.props)
        const{selectTab, spider, location} = this.props
        let routes = location.pathname.split('/')
        if(routes.length === 2){
            if(_.isNil(spider.selectedTab)){
                selectTab(this.tabs[0])
                browserHistory.push(`${location.pathname}/${this.tabs[0].name}`)
            }else{
                browserHistory.push(`${location.pathname}/${spider.selectedTab.name}`)
            }
        }
        else if(routes.length > 2){
            if(_.isNil(spider.selectedTab)){
                selectTab(this.tabs[0])
            }
        }
    }    

    componentDidUpdate(){
        //console.log("[Spider]-[componentDidUpdate] - Props:", this.props)
    }

    handleChange = (event, tab) =>{
        const {selectTab} = this.props        
        selectTab(tab)
        browserHistory.push(`/spider/${tab.name}`)
        
    }

    tabs = [
        {
            id:1,
            name: "in",
            display: "Instagram",
            disabled: false
        },
        {
            id:2,
            name: "fb",
            display: "Facebook",
            disabled: false
        }
    ]

    a11yProps = index => ({
        id: `full-width-tab-${index}`,
        key: `tab-spider-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    });

    render(){
        const {spider, children} = this.props
        return(
            <Box className={styles.root}>
                <AppBar position="static" color="transparent">
                    {
                        !_.isNil(spider.selectedTab) ?
                            <Tabs
                                value={spider.selectedTab}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="spider panel"
                            >
                                {
                                    this.tabs.map((item, index) => {
                                        return(
                                            <Tab label={item.display} 
                                                {...this.a11yProps(index)} 
                                                disabled={item.disabled}
                                                value={item} />
                                        )
                                    })
                                }
                            </Tabs> : <></>
                    }                    
                </AppBar>
                <TabPanel {...this.props}>
                    {children}
                </TabPanel>
                <Loader visible={spider.loader}></Loader>
            </Box>
        )
    }
}

class TabPanel extends Component{
    constructor(props){
        super(props)        
    }

    render(){
        const {children, spider} = this.props;
        return !_.isNil(spider.selectedTab) ? (
            <div
                role="tabpanel"
                hidden={_.isNil(children)}
                id={`simple-tabpanel-${spider.selectedTab.id}`}
                aria-labelledby={`simple-tab-${spider.selectedTab.id}`}                
            >
                {children}
            </div>
        ) : (<></>)
    }
}

export default Spider;