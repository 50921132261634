import { connect } from 'react-redux'
import Login from '../components/Spider/Instagram/Login/Login'

import {login, clear_cache} from '../actions/instagram/instaAPI'

const mapStateToProps = (state, ownProps) => ({
    instaAPI: state.instaAPI,
    location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
    login: (username, password, hash) => dispatch(login(username, password, hash)),
    clearCache: () => dispatch(clear_cache())
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)