import React, {Component, useEffect} from 'react'
import _ from 'lodash'
import Loader from '../../../Loader'
import {browserHistory} from 'react-router'


import {Box, Grid, CardHeader, Avatar, 
    Card, CardContent, CardActions,
    Button, Divider, Badge, Icon, TextField,
    IconButton, LinearProgress, Typography  
} from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab';

import './Main.scss'

export default class Main extends Component{
    constructor(props){
        super(props)
        this.finishedStatus = null
        this.msgStatus = null
    }

    redirectToLogin = path => {
        browserHistory.push(`${path}/login`)
    }

    componentDidMount() {
        const {instaAPI, isAuth, getCurrentUser} = this.props;
        if(!instaAPI.isAuthenticated){
            isAuth()
        }
        else if(instaAPI.isAuthenticated && _.isNil(instaAPI.influencer && !instaAPI.loader)){
            getCurrentUser();
        }
        this.updater = setInterval(this.checkStatus, 10000)
    }

    componentDidUpdate(){
        const {instaAPI, location, getCurrentUser, getLastPackage, setBtnMsg} = this.props;
        if(!instaAPI.isAuthenticated && !instaAPI.loader){
            this.redirectToLogin(location.pathname)
            return
        }
        if(instaAPI.isAuthenticated && _.isNil(instaAPI.influencer) && !instaAPI.loader){
            if(instaAPI.status === "login"){
                this.redirectToLogin(location.pathname)
                return
            }else{
                getCurrentUser();
            }            
        }
        if(instaAPI.isAuthenticated && !_.isNil(instaAPI.credential)){
            if((instaAPI.wsg.status === "started" || instaAPI.wsg.status === "process") && this.finishedStatus === null){
                setTimeout(() => {
                    this.finishedStatus = setInterval(this.checkFinishedStatus, 10000)
                }, 3000)
            }
            if(instaAPI.wsg.status === "finished" && this.finishedStatus !== null){
                clearInterval(this.finishedStatus)
                this.finishedStatus = null;
            }
            if(!instaAPI.messages.showBtn && this.msgStatus === null){
                this.msgStatus = {}
                console.log("Start check messages")
                setTimeout(this.setMsgStatusInterval, 60000)
                
                getLastPackage(instaAPI.credential.userName, instaAPI.credential.campaign)
            }
            if(!instaAPI.messages.showBtn && this.msgStatus !== null && instaAPI.messages.finished){
                clearInterval(this.msgStatus)
                setTimeout(() => {
                    setBtnMsg(true)
                }, 2000)
            }
        }
    }

    componentWillUnmount(){
        clearInterval(this.updater)
        if(this.finishedStatus != null){
            clearInterval(this.finishedStatus)
        }
        if(this.msgStatus != null){
            clearInterval(this.msgStatus)
        }
    }
    
    handleUpdateMedia = event => {
        const {instaAPI, updateMedia} = this.props;
        if(instaAPI.credential?.userName?.length > 0){
            updateMedia(instaAPI.credential?.userName);
        }
    }

    handleStartGenerate = event => {
        const {startWSG} = this.props;
        startWSG()
    }

    checkStatus = () => {
        const {instaAPI, checkUpdates} = this.props;
        if(instaAPI.isAuthenticated && !_.isNil(instaAPI.influencer) && !_.isNil(instaAPI.credential)){
            checkUpdates()
        }
    }

    checkFinishedStatus = () => {
        const {instaAPI, checkStatusWSG} = this.props;
        if(instaAPI.isAuthenticated && !_.isNil(instaAPI.credential)){
            checkStatusWSG(instaAPI.credential.userName, instaAPI.credential.campaign, "instagram")
        }
    }

    checkMsgStatus = () => {
        const {instaAPI, getMsgStatus} = this.props;
        console.log("Checking messages...")
        if(instaAPI.messages.packageId > 0){
            getMsgStatus(instaAPI.messages.packageId)
        }        
    }

    setMsgStatusInterval = () => {
        const {instaAPI} = this.props;
        if(instaAPI.messages.showBtn){
            console.log("Canceled interval")
            this.msgStatus = null
        }else{
            this.msgStatus = setInterval(this.checkMsgStatus, 5000)
            console.log("Started check messages")
        }
    }

    handleMessageText = event => {
        const {onChangeMessage} = this.props
        onChangeMessage(event.target.value)
    }
    
    handleClickSendBtn = () => {
        const {instaAPI, startSendMessages} = this.props;
        startSendMessages(instaAPI.messages.text)
    }

    render(){
        const {instaAPI} = this.props;

        const showProgressBar = instaAPI.wsg.status === "started" || instaAPI.wsg.status === "process";
        let buffer = 0, progress = 0;
        if(showProgressBar){
            if(instaAPI.credential?.numMediaCollected > 0){
                // how many was sent to generating
                buffer = Math.round(instaAPI.wsg.sent / instaAPI.credential.numMediaCollected * 100)
                if(buffer > 100){
                    buffer = 100
                }
                
                //how many was finished
                progress = Math.round((instaAPI.wsg.finished + instaAPI.wsg.errors) / instaAPI.credential.numMediaCollected * 100)
                if(progress > 100){
                    progress = 100
                }
            }   
        }

        let msgBuffer = 0, msgProgress = 0;
        if(!instaAPI.messages.showBtn){
            if(instaAPI.wsg.finished > 0){
                // how many was requested for sending
                msgBuffer = Math.round(instaAPI.messages.requsted / instaAPI.wsg.finished * 100)
                if(msgBuffer > 100){
                    msgBuffer = 100
                }
                
                //how many was sent
                msgProgress = Math.round((instaAPI.messages.sent + instaAPI.messages.errors) / instaAPI.wsg.finished * 100)
                if(msgProgress > 100){
                    msgProgress = 100
                }
            } 
        }
         
        return instaAPI.isAuthenticated ? (
            <Box m={"20px auto"}>
                <Grid container spacing={1} justify="center">
                    <Grid item md={8}>
                        <Card>
                            <CardHeader
                                className="header"
                                avatar={
                                    <Avatar aria-label="user profile picture"
                                        alt={instaAPI.influencer?.userDetail?.fullName || instaAPI.influencer?.fullName}
                                        src={instaAPI.influencer?.userDetail?.profilePicUrl || instaAPI.influencer?.profilePicture}
                                        className="avatar-size-bid"
                                    ></Avatar>
                                }
                                title={instaAPI.influencer?.userDetail?.fullName || instaAPI.influencer?.fullName}
                            />
                            <Divider />
                            <CardContent>
                                <Box p={2} >
                                    <Grid container spacing={1} justify="center" alignItems="center">
                                        <Grid item md={3}>
                                            <Box>Username:</Box>
                                        </Grid>
                                        <Grid item md={7}>
                                            <Box>{instaAPI.influencer?.userDetail?.username || instaAPI.influencer?.userName}</Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                                <Box p={2} >
                                    <Grid container spacing={1} justify="center" alignItems="center">
                                        <Grid item md={3}>
                                            <Box>Followers:</Box>
                                        </Grid>
                                        <Grid item md={7}>
                                            <Box>
                                                <Badge badgeContent={instaAPI.influencer?.userDetail?.followerCount || 0} max={9999999} showZero color="primary">
                                                    <Icon className="fas fa-user-secret" />
                                                </Badge></Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box p={2} >
                                    <Grid container spacing={1} justify="center" alignItems="center">
                                        <Grid item md={3}>
                                            <Box>Media Collected:</Box>
                                        </Grid>
                                        <Grid item md={1}>
                                            <Box m={1}>
                                                <Badge badgeContent={instaAPI.credential?.numMediaCollected || 0} max={9999999} showZero color="primary">
                                                    <Icon className="fas fa-photo-video" />
                                                </Badge>
                                            </Box>
                                        </Grid>
                                        <Grid item md={6}>
                                            {/* <IconButton aria-label="update media" onClick={this.handleUpdateMedia} >
                                                <Icon className="fas fa-sync-alt"></Icon>
                                            </IconButton> */}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box p={2} >
                                    <Grid container spacing={1} justify="center" alignItems="center">
                                        <Grid item md={3}>
                                            <Box>Websites Generated:</Box>
                                        </Grid>
                                        <Grid item md={1}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Badge badgeContent={instaAPI.wsg.finished} max={9999999} showZero color="primary">
                                                    <Icon className="fas fa-globe" />
                                                </Badge>
                                                {
                                                    instaAPI.wsg.errors > 0 ?
                                                    <Badge badgeContent={instaAPI.wsg.errors} max={9999999} showZero color="primary">
                                                        <Icon style={{color: "red"}} className="fas fa-exclamation-circle" />
                                                    </Badge>
                                                    : <></>
                                                }
                                                
                                            </Box>
                                        </Grid>
                                        <Grid item md={1}>
                                            <IconButton aria-label="start generate web sites" 
                                                onClick={this.handleStartGenerate}
                                                disabled={instaAPI.wsg.status !== "finished"}>
                                                <Icon className="fas fa-play"></Icon>
                                            </IconButton>
                                        </Grid>
                                        <Grid item md={5}>
                                            {
                                                showProgressBar ?
                                                    <Box display="flex" alignItems="center">
                                                        <Box width="100%" mr={1}>
                                                            <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                                                        </Box>
                                                        <Box minWidth={35}>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {`${progress}%`}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <></>
                                            }
                                            
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box p={2} >
                                    <Grid container spacing={1} justify="center" alignItems="center">                                        
                                        <Grid item md={10}>
                                            <Box>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Message:"
                                                    multiline
                                                    rows="4"
                                                    className="message-box"
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={this.handleMessageText}
                                                    value={instaAPI.messages.text}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item md={10}>
                                            <Box>
                                                <Button variant="contained" 
                                                    color="primary" 
                                                    onClick={this.handleClickSendBtn}
                                                    disabled={!instaAPI.messages.showBtn || instaAPI.wsg.status !== "finished"}
                                                >
                                                    Send
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box p={2} >
                                    <Grid container spacing={1} justify="center" alignItems="center">
                                        <Grid item md={3}>
                                            <Box>Sent Messages:</Box>
                                        </Grid>
                                        <Grid item md={1}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Badge badgeContent={instaAPI.messages.sent || 0} max={999999} showZero color="primary">
                                                    <Icon className="fas fa-mail-bulk" />
                                                </Badge>
                                                {
                                                    instaAPI.messages.errors > 0 ?
                                                    <Badge badgeContent={instaAPI.messages.errors} max={9999999} showZero color="primary">
                                                        <Icon style={{color: "red"}} className="fas fa-exclamation-circle" />
                                                    </Badge>
                                                    : <></>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item md={6}>
                                            {
                                                instaAPI.messages.showBtn ? 
                                                    <></> 
                                                    :
                                                    <Box display="flex" alignItems="center">
                                                        <Box width="100%" mr={1}>
                                                            <LinearProgress variant="buffer" value={msgProgress} valueBuffer={msgBuffer} />
                                                        </Box>
                                                        <Box minWidth={35}>
                                                            <Typography variant="body2" color="textSecondary">
                                                                {`${msgProgress}%`}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                            }
                                            
                                        </Grid>
                                    </Grid>
                                </Box>
                                {
                                    _.isBoolean(instaAPI.error) && instaAPI.error ?
                                        (
                                            <Box m={1}>
                                                <Alert severity="error">
                                                    <AlertTitle><strong>Error</strong></AlertTitle>
                                                    {instaAPI.message}
                                                </Alert>
                                            </Box>
                                        ) : (<></>)
                                }
                            </CardContent>
                            <CardActions className="just-center">
                                
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Loader visible={instaAPI.loader}></Loader>
            </Box>
        ) : (<Box></Box>)
    }
}