import { connect } from 'react-redux'
import ChallengeCode from '../components/Spider/Instagram/Challenge/ChallengeCode'

//** Actions: */
import {sendcode, clear_cache} from '../actions/instagram/instaAPI'

const mapStateToProps = (state, ownProps) => ({
    instaAPI: state.instaAPI,
    location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
    sendcode: (username, code, type, crds) => dispatch(sendcode(username, code, type, crds)),
    clearCache: () => dispatch(clear_cache())
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeCode)