import * as types from '../actions/spider/types'

const InitialState = {
    selectedTab: null,
    selectedIndex: 0,
    loader: false    
}

const spider = (state=InitialState, action) => {
    switch(action.type){
        case types.SELECT_TAB:
            return {
                ...state,
                selectedTab: action.tab
            }
        default:
            return state;
    }
}

export default spider;