import { connect } from 'react-redux'
import Spider from '../components/Spider/Spider'

//*** ACTIONS: ***/
import {selectTab} from '../actions/spider/spider'

const mapStateToProps = (state, ownProps) => ({
    ...state,
    location: ownProps.location
})

const mapDispatchToProps = dispatch => ({
    selectTab: tab => dispatch(selectTab(tab))
})

export default connect(mapStateToProps, mapDispatchToProps)(Spider)