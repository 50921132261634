export const LOADER = 'LOADER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const CHALLENGE_REQUIRED = 'CHALLENGE_REQUIRED'
export const CHALLENGE_CODE = 'CHALLENGE_CODE'
export const CHALLENGE_PHONE_REQUIRED = 'CHALLENGE_PHONE_REQUIRED'
export const CHALLENGE_TWOFACTOR = 'CHALLENGE_TWOFACTOR'
export const CHALLENGE_SUCCESS = 'CHALLENGE_SUCCESS'
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS'
export const CURRENT_CREDENTIAL_SUCCESS = 'CURRENT_CREDENTIAL_SUCCESS'
export const CLEAR_CACHE = 'CLEAR_CACHE'
export const CHECK_UPDATES_SUCCESS = 'CHECK_UPDATES_SUCCESS'
export const UPDATE_WSG_STATUS = 'UPDATE_WSG_STATUS'
export const WSG_STATUS_FINISHED_SUCCESS = 'WSG_STATUS_FINISHED_SUCCESS'
export const CHANGE_EVENT = 'CHANGE_EVENT'
export const SET_PACKAGE_ID = 'SET_PACKAGE_ID'
export const SET_VISIBILITY_BTN_MSG = 'SET_VISIBILITY_BTN_MSG'
export const STATUS_MSG_SUCCESS = 'STATUS_MSG_SUCCESS'