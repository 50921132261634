import React, { Component } from "react";
import * as utils from '../utils'

import { Container, Grid, AppBar, 
    Toolbar, IconButton, Typography, Button,
    List, ListItem, ListItemIcon, ListItemText,
    Divider, Icon  } 
    from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import styles from './Dashboard.module.scss'

import {browserHistory} from 'react-router'

class Dashboard extends Component{
    constructor(props){
        super(props)        
    }

    componentDidMount(){
        console.log("[Dashboard]-[componentDidMount] - Props:", this.props)
        const {dashboard, location, selectCategory} = this.props
        if(location.pathname.length > 1){
            let routes = location.pathname.split('/')
            let currentItem = utils.findElementByPropName(this.itemList, "name", routes[1])
            selectCategory(currentItem)
        }
    }    

    componentDidUpdate(){
        //console.log("[Dashboard]-[componentDidUpdate] - Props:", this.props)
    }

    itemList = [
        {
            id: 1,
            display: "Spider",
            name: "spider",
            icon: "fas fa-spider"
        }
    ]

    handleItemClick = (event, item) => {
        const {selectCategory} = this.props
        selectCategory(item)
        browserHistory.push(`/${item.name}`)
    }

    render(){
        const {dashboard, children} = this.props
        return(
            <Container maxWidth={false} className={styles.Container}>
              <Grid container spacing={0}>
                <Grid item md={12}>
                    <div className={styles.appBar_root}>
                        <AppBar position="static">
                            <Toolbar>
                                <IconButton edge="start"  color="inherit" aria-label="menu">
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" className={styles.title}>
                                    Dashboard
                                </Typography>
                                {/* <Button color="inherit">Login</Button> */}
                            </Toolbar>
                        </AppBar>
                    </div>                    
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={1} >
                    <Grid item md={2}>
                        <List component="nav" aria-label="main folders">
                            {
                                this.itemList.map(item => {
                                    return(
                                        <ListItem key={`item-id-${item.id}`} button 
                                            selected={item.id === dashboard.selectedCategory?.id} 
                                            onClick={(e) => this.handleItemClick(e, item)}>
                                            <ListItemIcon><Icon className={`${item.icon}`}></Icon></ListItemIcon>
                                            <ListItemText>{item.display}</ListItemText>
                                        </ListItem>
                                    )
                                })
                            }                            
                        </List>                        
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item md >
                        {children}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
        )
    }
}

export default Dashboard;